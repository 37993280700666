export const hasPermissionShowAllPending = (userId) => {
    const userPermission = process.env.REACT_APP_SHOW_ALL_PENDING_NOTQUEUE;

    if (!userPermission) {
      // Se a variável de ambiente não está definida, negar permissão
      return false;
    }
  
    if (userPermission === "ALL") {
      // Se a variável de ambiente é "ALL", conceder permissão a todos
      return true;
    }
  
    // Se a variável de ambiente contém uma lista de IDs, verificar se o ID específico está presente
    const allowedIds = userPermission.split(',').map(id => id.trim());
    return allowedIds.includes(userId.toString());
} 

export const hasPermissionClosedAll = (userId) => {
    const userPermission = process.env.REACT_APP_SHOW_ALL_CLOSED_NOTQUEUE;

    if (!userPermission) {
    // Se a variável de ambiente não está definida, negar permissão
    return false;
    }

    if (userPermission === "ALL") {
    // Se a variável de ambiente é "ALL", conceder permissão a todos
    return true;
    }

    // Se a variável de ambiente contém uma lista de IDs, verificar se o ID específico está presente
    const allowedIds = userPermission.split(',').map(id => id.trim());
    return allowedIds.includes(userId.toString());
}